<template>
  <b-modal id="tag-modal"
           :visible="true"
           title="Bulk Share Lessons"
           no-close-on-backdrop
           no-close-on-esc
           @hide="$emit('close')"
           @close="$emit('close')"
  >
    <div class="modal-body">
      <!-- Tag input using vue-select -->
      <label for="tag-input">Enter Tags:</label>
      <v-select
        id="tag-input"
        v-model="selectedTags"
        multiple
        taggable
        push-tags
        :options="[]"
        placeholder="Add numeric tags... (type and press Enter to add)"
        class="mb-3"
        :reduce="tag => Number(tag)"
        :create-option="createNumericOption"
        @input="onTagsChange"
      />
      <small class="text-muted">Type any numeric value and press Enter to add it to the list</small>
      <div v-if="error"
           class="text-danger mt-2"
      >
        {{ error }}
      </div>
    </div>

    <!-- Modal footer with submit button -->
    <template #modal-footer>
      <b-button variant="secondary"
                class="mr-1"
                @click="$bvModal.hide('tag-modal')"
      >
        Cancel
      </b-button>
      <b-button variant="primary"
                :disabled="selectedTags.length === 0 || isProcessing"
                @click="handleSubmit"
      >
        <b-spinner v-show="isProcessing"
                   small
        /> Submit
      </b-button>
    </template>
  </b-modal>
</template>

<script>
// Import specific components from bootstrap-vue
import { BButton, BModal, BSpinner } from 'bootstrap-vue'
// Import vue-select
import vSelect from 'vue-select'
import useJwt from '@/auth/jwt/useJwt'

export default {
  name: 'TagInputModal',
  components: {
    BButton,
    BModal,
    BSpinner,
    vSelect,
  },
  props: {
    lessonGroupId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      selectedTags: [],
      error: null,
      isProcessing: false,
    }
  },
  methods: {
    handleSubmit() {
      const params = {
        lesson_group_id: this.lessonGroupId,
        lesson_id: this.selectedTags,
      }
      this.isProcessing = true
      useJwt.storeSharedLessons(params).then(response => {
        this.showSuccessMessage(response)
      }).finally(() => {
        this.$emit('close')
        this.$emit('onRefetch')
        this.isProcessing = false
      })
    },
    onHide() {
      // Emit event when modal is hidden
      this.$emit('hide')
    },
    onTagsChange(value) {
      this.error = null
      // Filter out any non-numeric values
      this.selectedTags = (value || []).filter(tag => typeof tag === 'number' && !isNaN(tag))
    },
    createNumericOption(newTag) {
      // Check if input is numeric
      const numValue = Number(newTag)

      if (isNaN(numValue)) {
        this.error = 'Only numeric values are accepted'
        return null
      }

      this.error = null
      return numValue
    },
  },
}
</script>

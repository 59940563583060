const { default: axios } = require('axios')

const instance = axios.create({
  baseURL: 'https://api.datablockai.com/api/v1',
  headers: { 'Content-Type': 'application/json' },
})

export const getAIScoreForDemo = args => instance.post('/gec/ai-report', args)

export const getDemoBotResponse = (args, headers) => instance.post('/gec/learning-coach', args, headers)

export const getFactorialLessonData = args => instance.post('/ai/process', args)

export const generateLessonSummary = args => instance.post('/lesson-summary/generate', args)

export const parsePdfFile = args => instance.post('/gec/llama-parse', args)

export const makeDomainStandard = args => instance.post('/domain/maker', args)
